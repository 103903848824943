import React, { useLayoutEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { Controller, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import Layout from './Layout'
import LeftArrow from './assets/left-arrow.svg'
import RightArrow from './assets/right-arrow.svg'
import Select from './Select';
import Swipper from './Swiper';
import useData from './useData';

// Function to find the parent object
function findParent(name, data) {
    for (const category in data) {
        const product = data[category].find(item => item.name === name);
        if (product) {
            return {
                category: { [category]: data[category] },
                product
            }; // Return the parent object
        }
    }
    return null; // Product not found
}

const isVideo = (fileName) => {
    const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.webm'];
    return videoExtensions.some(ext => fileName.endsWith(ext));
};

const ProductRoute = () => {
    const { id } = useParams();

    const {
        data
    } = useData();

    const parsedData = findParent(id, data)

    if (!parsedData?.product) {
        return (
            <Layout>
                <div className='flex items-center justify-center flex-col gap-8 mt-20'>
                    <h1 className='text-4xl font-bold text-center'>Product not found!</h1>
                </div>
            </Layout>
        )
    }

    if (id) {
        return (
            <Layout>
                <ProductPage parsedData={parsedData} />
            </Layout>
        );
    }

};

export default ProductRoute

const ProductPage = ({ parsedData }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState();
    const [firstSwiper, setFirstSwiper] = useState();
    const [secondSwiper, setSecondSwiper] = useState();
    const swiper1Ref = useRef();
    const swiper2Ref = useRef();

    const defaultPrice = typeof parsedData.product.price === 'number' ? parsedData.product.price : parsedData.product.price?.[0] || 0;

    const [price, setPrice] = useState(defaultPrice);

    const [vars, setVars] = useState({});

    useLayoutEffect(() => {
        if (swiper1Ref.current !== null) {
            swiper1Ref.current.controller.control = swiper2Ref.current;
        }
    }, []);


    const onChange = (e, name) => {
        const value = e.target.value;

        const _vars = {
            ...vars,
            [name]: Number(value)
        };

        setVars(_vars)

        const varsValues = Object.values(_vars);

        const sum = varsValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setPrice(defaultPrice + sum)
    }

    const categoryName = Object.keys(parsedData.category)?.[0] || '';
    
    const { product } = parsedData


    const swiperData = parsedData.category[categoryName].filter((product_) => product_.name !== product.name);

    const allImages = [product.image_path, ...(product?.image_gallery || [])];


    return (
        <div className="">
            <div className="flex flex-col md:flex-row md:gap-20 md:mt-10">
                <div className='overflow-hidden'>

                    <>
                        <div className="h-[400px] md:h-[550px]  relative">
                            <Swiper
                                onSwiper={(swiper) => {
                                    if (swiper1Ref.current !== null) {
                                        swiper1Ref.current = swiper;
                                    }
                                }}
                                preloadImages={false}
                                controller={{ control: secondSwiper }}
                                spaceBetween={10}
                                slidesPerView={1}
                                grabCursor={true}
                                thumbs={{
                                    swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                                }}
                                modules={[FreeMode, Thumbs, Controller]}
                                className=" w-[400px] md:w-[700px]  h-[300px] md:h-[454px]  rounded-xl"
                            >
                                {
                                    allImages?.map((gallery, index) => {
                                        return (
                                            <SwiperSlide key={index}>

                                                {
                                                    isVideo(gallery) ? <video controls
                                                        autoPlay
                                                        muted
                                                        loop src={`../assets/${gallery}`} className="h-full w-full object-contain" /> :
                                                        <img src={`../assets/${gallery}`} className='h-full w-full object-contain' />
                                                }

                                            </SwiperSlide>
                                        )
                                    })
                                }


                            </Swiper>


                             <Swiper
                                controller={{ control: firstSwiper }}
                                loop={false}
                                spaceBetween={10}
                                slidesPerView={8}
                                watchSlidesProgress
                                touchRatio={0.2}
                                preloadImages={false}
                                lazy
                                slideToClickedSlide={true}
                                onSwiper={setThumbsSwiper}
                                modules={[Navigation, Thumbs, Controller]}
                                className="h-[100.4px] w-[500px] md:w-[700px] mt-[10px] rounded-xl  "
                            >

                                {
                                    allImages?.map((gallery, index) => {
                                        return (
                                            <SwiperSlide key={index} className="w-[100px]">
                                                {
                                                    isVideo(gallery) ? <video     src={`../assets/${gallery}`} className="rounded ml-2  h-[80px] md:h-[100px] border border-black" /> :
                                                        <img src={`../assets/${gallery}`} className="rounded ml-2  h-[80px] md:h-[100px] border border-black" />
                                                }
                                            </SwiperSlide>
                                        )
                                    })
                                }

                            </Swiper> 

                            <div className='absolute top-1/2 -mt-20 left-0 z-[2000] cursor-pointer bg-white rounded-full' onClick={() => swiper1Ref.current.slidePrev()}>
                                <img src={LeftArrow} className='w-12 ' />
                            </div>
                            <div className='absolute top-1/2 -mt-20 right-0 z-[2000] cursor-pointer bg-white rounded-full' onClick={() => swiper1Ref.current.slideNext()}>
                                <img src={RightArrow} className='w-12 ' />

                            </div>
                        </div>
                    </>
                </div>
                <div className='flex flex-col gap-4'>
                    <h1 className='font-bold text-3xl md:text-4xl text-black'>{product.name}</h1>


                    {
                        typeof product.price === 'number' ?
                            <span className='text-gray-700 font-medium text-2xl'>From ${product.price.toFixed(2)}</span>
                            : <span className='text-gray-700 font-medium text-2xl'>${product.price?.[0].toFixed(2)} - ${product.price?.[1].toFixed(2)}</span>
                    }

                    <ul className="list-disc ml-6 text-xl gap-2 md:my-10 flex flex-col">
                        {
                            product.info?.map((inf, index) => {
                                return <li key={index}>{inf}</li>
                            })
                        }
                    </ul>
                    <>
                        {
                            product?.attributes.map((attr, index) => {
                                return (
                                    <Select
                                        key={index}
                                        label={attr.name}
                                        options={attr.options}
                                        onChange={(e) => onChange(e, attr.name)}
                                    />

                                )
                            })
                        }
                    </>
                    <span className='font-bold text-black text-2xl'>Total <span className='underline'>${price.toFixed(2)}</span></span>
                    <span className='text-gray-700 text-xl font-semibold mt-4'>Category: {product.category}</span>
                </div>

            </div>

            <div className=''>
                <Swipper title={categoryName} data={swiperData} />
            </div>
        </div>
    )
}

