
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import ProductPage from './ProductPage';
import Categories from './Categories';
import ContactUs from './ContactUs';
import NotFound from './NotFound';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/product/:id" element={<ProductPage />} />
        <Route path="/category/:id" element={<Categories />} />
        <Route path="/category/:id/:sub" element={<Categories />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
