import Logo from './assets/logo.png';
import Telegram from './assets/telegram.svg';
import Signal from './assets/signal.png';
import { useState } from 'react';
import ScrollTop from './ScrollTop';
import { Link } from 'react-router-dom';
import HamburgerIcon from './assets/burger-menu.svg';
import CloseIcon from './assets/close-icon.svg';
import TelegramIcon from './assets/telegram-icon.svg';
import SignalIcon from './assets/signal-icon.svg';
import useData from './useData';
import useClickOutside from './useClickOutside';

const Layout = ({ children, before }) => {
    const {
        data
    } = useData();

    const [isHovered, setIsHovered] = useState(false);

    const [subCategories, setSubCategories] = useState()

    if (!data) return null

    return (
        <>
            <header className="flex flex-col bg-black sticky left-0 top-0 z-[3000] ">
                <div className=" container mx-auto flex items-center justify-between py-6">
                    {/* <img src={Logo} className='cursor-pointer' /> */}
                    <Navbar data={data} />
                    <Link to='/' className='text-primary text-2xl  md:text-5xl font-bold'>TrapSocietyLA</Link>
                    <SearchBar className='hidden md:block' data={data} />
                    <Link to='/contact-us' className="bg-primary text-black font-semibold group text-sm  md:text-base px-3 md:px-6 py-1 md:py-3 rounded-sm flex items-center gap-1 hover:bg-transparent hover:border-primary border-2 border-transparent hover:text-primary cursor-pointer transition-all duration-300 ">
                        <span>SUPPORT</span>
                        <svg
                            className="  w-4  group-hover:fill-primary transition-all duration-300"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" />
                        </svg>
                    </Link>
                </div>

                <div className='w-full bg-primary border-b border-black'>

                    <SearchBar className='md:hidden' data={data} />

                    <div className='  gap-10 mx-auto items-center justify-center py-4 hidden md:flex relative'>

                        {
                            Object.keys(data).map((key) => {
                                return (
                                    <Link
                                        className='relative'
                                        onClick={() => {
                                            setIsHovered('')
                                            setSubCategories([])
                                        }}
                                        onMouseEnter={() => {
                                            const test = data[key].map(product => product.sub_category).filter((x) => !!x)

                                            if (test.length === 0) return;

                                            const uniqueSubcategories = [...new Set(test)];

                                            setSubCategories(uniqueSubcategories);
                                            setIsHovered(key)
                                        }}
                                        onMouseLeave={() => {
                                            setIsHovered('')
                                            setSubCategories([])
                                        }}
                                        key={key} to={`/category/${key}`}>
                                        <span className='uppercase text-black font-bold text-lg'>{key}</span>
                                        {
                                            isHovered === key && subCategories.length !== 0 &&
                                            <div className='flex flex-col absolute top-full left-0 bg-black border-primary rounded-md text-primary w-44 p-3   '>
                                                {
                                                    subCategories.map((x, index) => {
                                                        return <Link className='w-full font-semibold py-3' key={index} to={`/category/${key}/${x}`}>{x}</Link>
                                                    })
                                                }
                                            </div>
                                        }
                                    </Link>
                                )
                            })
                        }

                    </div>

                </div>
            </header>
            {before}
            <main className="flex flex-col container mx-auto mt-7 mb-10 md:mb-20 min-h-[50vh]">
                {children}
            </main>
            <footer className="flex flex-col ">
                <div className='bg-primary '>
                    <div className='flex flex-col md:flex-row gap-4 md:items-center justify-around py-9 container mx-auto'>
                        <div className='flex items-center gap-3'>
                            <img src={Telegram} className='w-12' />
                            <div className='flex flex-col gap-2'>
                                <span className='text-black font-semibold'>Telegram Live Support</span>
                                <span className='text-black'>@trapsocietysupport</span>
                            </div>
                        </div>
                        {/* <div className='flex items-center gap-3'>
                            <img src={Signal} />
                            <div className='flex flex-col gap-1'>
                                <span className='text-black font-semibold'>Signal Live Support</span>
                                <span className='text-black'>@trapsocietysupport</span>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='bg-black py-10 md:py-20'>
                    <div className='flex items-center justify-center'>
                        <div className='flex flex-col gap-7'>
                            <Link to='/' className='text-primary text-3xl md:text-5xl font-bold mx-auto'>TrapSocietyLA</Link>
                            <span className='text-sm md:text-base text-[#888888] px-6 md:px-0'>Copyright © 2024 TrapSocietyLA LA, All Rights Reserved | <Link to='/contact-us' className='text-base text-white underline cursor-pointer'>Contact Us</Link></span>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default Layout


const SearchBar = ({ className, data }) => {

    const [searchedData, setSearchedData] = useState([]);

    const { isOpen, setIsOpen, dropdownRef } = useClickOutside()

    const { isOpen: isSearchOpen, setIsOpen: setIsSearchOpen, dropdownRef: searchRef } = useClickOutside()

    const onSearch = (e) => {
        setIsSearchOpen(true);

        const value = e.target.value;

        if (!value) {
            setSearchedData([]);
            return;
        }

        const loweredCaseValue = value.toLowerCase().replace(/\s+/g, '');

        const results = [];

        for (const category in data) {
            data[category].forEach(product => {
                if (product.name.toLowerCase().replace(/\s+/g, '').includes(loweredCaseValue) || category.toLowerCase().replace(/\s+/g, '').includes(loweredCaseValue)) {
                    results.push(product);
                }
            });
        }

        setSearchedData(results)
    }

    return (
        <div className={`max-w-2xl mx-auto w-full relative ${className}`}>
            <div className="flex">
                <label
                    htmlFor="search-dropdown"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                    Your Email
                </label>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex-shrink-0 z-10 inline-flex items-center py-2.5  pl-6 pr-4 md:px-4  font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s hover:bg-gray-200 "
                >
                    All categories{" "}
                    <svg
                        className="w-2.5 h-2.5 ms-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 4 4 4-4"
                        />
                    </svg>
                </button>
                {
                    isOpen &&
                    <div
                        ref={dropdownRef}
                        className="z-10 mt-2 absolute top-full left-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                    >
                        <ul
                            className="py-2 text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdown-button"
                        >
                            {
                                Object.keys(data).map((key) => {
                                    return (
                                        <Link key={key} to={`/category/${key}`} onClick={() => setIsOpen(false)}>
                                            <li key={key}>
                                                <button
                                                    className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    {key}
                                                </button>
                                            </li>
                                        </Link>

                                    )
                                })
                            }

                        </ul>
                    </div>
                }

                <SearchInput onSearch={onSearch} />
            </div>
            {
                searchedData.length !== 0 && isSearchOpen &&
                <div ref={searchRef} className='absolute w-full top-full left-0  border border-gray-400'>
                    {searchedData.map((item, index) => {
                        return (
                            <Link to={`/product/${item.name}`}>
                                <div key={index} className='flex items-center justify-between px-7 py-2 bg-white  hover:bg-gray-200 '>
                                    <div className='flex items-center gap-10'>
                                        <img src={`../assets/${item.image_path}`} className='h-14' />
                                        <div className='flex flex-col gap-2'>
                                            <span className='font-bold'>{item.name}</span>
                                            <span>{item.category}</span>
                                        </div>
                                    </div>
                                    {
                                        typeof item.price === 'number' ?
                                            <span>From ${item.price.toFixed(2)}</span>
                                            : <span>${item.price?.[0].toFixed(2)} - ${item.price?.[1].toFixed(2)}+</span>
                                    }
                                </div>
                            </Link>
                        )
                    })}
                </div>
            }


        </div>
    )
}

const SearchInput = ({ onSearch }) => {
    return (
        <div className="relative w-full">
            <input
                type="search"
                id="search-dropdown"
                className="block p-2.5 py-4 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e border-s-gray-50 border-s-2 border border-gray-300 "
                placeholder="Search products"
                required=""
                onChange={onSearch}
            />
        </div>
    )
}

const Navbar = ({ data }) => {
    const [open, setOpen] = useState(false);

    const [isHovered, setIsHovered] = useState(false);

    const [subCategories, setSubCategories] = useState()
    return (
        <div className='md:hidden '>
            <img src={HamburgerIcon} onClick={() => {
                setOpen(true)
                document.body.style.overflow = 'hidden';
            }} className='border border-slate-600 rounded-md p-1' />
            <div className={`fixed flex flex-col justify-between top-0 left-0 z-[4000] bg-black h-full w-4/5 transition-all duration-500 ${open ? 'translate-x-0 opacity-100 visible pointer-events-auto' : '-translate-x-full opacity-0 invisible pointer-events-none'}`}>
                <div className='flex flex-col gap-6  p-7'>
                    <Link to='/' className='text-primary text-3xl text-center mx-auto font-bold'>TrapSocietyLA</Link>
                    <SearchInput />
                    {
                        Object.keys(data).map((key) => {
                            return (
                                <>
                                <div className='flex items-center gap-2'>
                                    <Link key={key} to={`/category/${key}`} >
                                        <span className='uppercase text-white font-bold text-lg'>{key}</span>


                                    </Link>

                                    <div
                                        onClick={() => {
                                            if(isHovered === key){
                                                
                                            setSubCategories([]);
                                            setIsHovered('')
                                            return;
                                            }
                                            const test = data[key].map(product => product.sub_category).filter((x) => !!x)

                                            if (test.length === 0) return;

                                            const uniqueSubcategories = [...new Set(test)];

                                            setSubCategories(uniqueSubcategories);
                                            setIsHovered(key)
                                        }}

                                    >

                                        <svg fill="#fff" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

                                            <g data-name="Layer 2">

                                                <g data-name="arrow-ios-downward">

                                                    <rect width="24" height="24" opacity="0" />

                                                    <path d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z" />

                                                </g>

                                            </g>

                                        </svg>

                                    </div>
                                    </div>

                                    {
                                        isHovered === key && subCategories.length !== 0 &&
                                        <div className='flex flex-col  w-full  border-primary rounded-md text-primary  p-3 gap-5 '>
                                            {
                                                subCategories.map((x, index) => {
                                                    return <Link className='w-full font-semibold' key={index} to={`/category/${key}/${x}`}>{x}</Link>
                                                })
                                            }
                                        </div>
                                    }
</>
                            )
                        })
                    }
                </div>
                <div className='mt-auto bg-[#111] bottom-0 border-t-2 border-slate-800 flex flex-col gap-3 p-6'>
                    <span className='text-primary underline text-xl uppercase font-bold'>Chat support</span>
                    <div className='flex items-center gap-3'>
                        <img src={TelegramIcon} className='' />
                        <span className='text-white font-semibold'>@trapsocietysupport</span>
                    </div>

                    <div className='flex items-center gap-3'>
                        <img src={SignalIcon} className='' />
                        <span className='text-white font-semibold'>@Signal_usr</span>
                    </div>
                </div>
            </div>


            <div onClick={() => {
                setOpen(false)
                document.body.style.overflow = 'auto';

            }} className={`fixed top-0 left-0 z-[3900] bg-black/80 h-full w-full transition-all duration-500 ${open ? 'opacity-100 visible pointer-events-auto' : 'opacity-0 invisible pointer-events-none'}`}>

            </div>

            <div onClick={() => {
                setOpen(false)
                document.body.style.overflow = 'auto';

            }} className={`fixed top-5 right-5 z-[3900]   transition-all duration-500 ${open ? 'opacity-100 visible pointer-events-auto' : 'opacity-0 invisible pointer-events-none'}`}>
                <img src={CloseIcon} />
            </div>
        </div>
    )
}