import Layout from "./Layout"
import Swipper from "./Swiper";
import Banner from './assets/baner.png'
import useData from "./useData";

const Homepage = () => {
    const {
        data
    } = useData();

    if(!data) return null
 
    return (
        <Layout>
            <div className="my-5 md:my-10">
                <img src={Banner} />
            </div>
            {
                Object.keys(data)?.map((key) => {
                    return <Swipper key={key} title={key} data={data[key]} />

                })
            }
        </Layout>
    )
}

export default Homepage;