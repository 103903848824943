
export default function Select({ label, options = [], onChange }) {
  return (
    <div className="">
      <label className="block text-xl font-medium leading-6 text-gray-900  ">
        {label}
      </label>
      <select
        onChange={onChange}
        className="mt-2 block w-full h-12 placeholder:text-xl text-xl rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        <option hidden selected>Select one...</option>
        {
          options.map((option) => {
            return <option className="text-xl" key={option.key} value={option.key}>{option.value}</option>
          })
        }
      </select>
    </div>
  )
}
