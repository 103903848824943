import { useParams } from "react-router-dom";
import Card from "./Card";
import Layout from "./Layout";
import useData from "./useData";

const Categories = () => {
    const { id, sub } = useParams();
    
    const {
        data
    } = useData();

     const category = data?.[id];


     const subProducts = category?.filter((x) => x.sub_category === sub)

     if(sub && subProducts?.length === 0) {
        return (
            <Layout>
            <div className='flex items-center justify-center flex-col gap-8 mt-20'>
                <h1 className='text-4xl font-bold text-center'>Sub category not found!</h1>
            </div>
        </Layout>
        )
     }
    if (category) {
        return (
            <Layout

                before={
                    <div className="bg-black py-10">
                        <div className="container mx-auto">
                            <span className="text-primary text-3xl uppercase font-bold flex items-center justify-center">{sub || id}</span>

                        </div>
                    </div>
                }
            >
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {
                        (!!sub ? subProducts : category).map((data, index) => {
                            return <Card item={data} key={index} />
                        })
                    }
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className='flex items-center justify-center flex-col gap-8 mt-20'>
                <h1 className='text-4xl font-bold text-center'>Category not found!</h1>
            </div>
        </Layout>
    )
}

export default Categories;