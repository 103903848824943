import { useEffect, useState } from "react";


const useData = () => {
    const [data, setData] = useState()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const response = await fetch('/data.json');

        const parsed = await response.json()

        const groupedByCategory = parsed.reduce((acc, product) => {
            const category = product.category;

            if (!acc[category]) {
                acc[category] = [];
            }

            acc[category].push(product);

            return acc;
        }, {});

        setData(groupedByCategory) 
    }

    return {
        data
    };
}

export default useData;