import { useEffect, useRef, useState } from "react";

const useClickOutside = () => {

    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);
  
    const toggleDropdown = () => {
      setIsOpen((prev) => !prev);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return {
        isOpen,
        setIsOpen,
        dropdownRef
    }
}

export default useClickOutside;