// NotFound.js
import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Layout>
            <div className='flex items-center justify-center flex-col gap-8 mt-20'>
                <h1 className='text-4xl font-bold'>404 - Page Not Found</h1>
                <p className='text-2xl font-semibold text-center'>Sorry, the page you are looking for does not exist.</p>
                <Link to='/' className="bg-primary text-black font-semibold group  text-base  px-6 py-3 rounded-sm flex items-center gap-1   border-2 border-transparent  cursor-pointer transition-all duration-300 ">
                    <span>Go to homepage</span>
                </Link>
            </div>
        </Layout>
    );
};

export default NotFound;
