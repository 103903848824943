import Layout from "./Layout"

const ContactUs = () => {
    return (
        <Layout>
        <div className='flex items-center justify-center flex-col gap-8 mt-20'>
            <h1 className='text-4xl font-bold text-center'>Contact us at the telegram below</h1>
            <div className="bg-primary text-black font-semibold group  text-xl  px-6 py-3 rounded-lg flex items-center gap-1   border-2 border-transparent  cursor-pointer transition-all duration-300 ">
                <span>@trapsocietysupport</span>
            </div>
        </div>
    </Layout>
    )
}

export default ContactUs