import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import LeftArrow from './assets/left-arrow.svg'
import RightArrow from './assets/right-arrow.svg'
import Card from './Card';


export function isMobile() {
    return window.innerWidth <= 768; // You can adjust the width threshold as needed
}

const Swipper = ({ data = [], title = '' }) => {
    const swiperRef = useRef(null)

    if (data.length === 0) return null

    return (
        <div className="flex flex-col my-4 md:my-10 gap-3 md:gap-7">
            <h2 className='text-black font-bold text-xl md:text-4xl text-center uppercase'>{title}</h2>
            <div className='relative'>
                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    modules={[Navigation]}
                    spaceBetween={isMobile() ? 20 : 40}
                    loop
                    slidesPerView={isMobile() ? 2 : 4}
                >
                    {
                        data?.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Card item={item} />
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
                <div className='absolute top-1/2 left-0 z-[2000] cursor-pointer bg-white rounded-full' onClick={() => swiperRef.current.slidePrev()}>
                    <img src={LeftArrow} className='w-8 md:w-12 ' />
                </div>
                <div className='absolute top-1/2 right-0 z-[2000] cursor-pointer bg-white rounded-full' onClick={() => swiperRef.current.slideNext()}>
                    <img src={RightArrow} className='w-8 md:w-12 ' />

                </div>
            </div>
        </div>

    );
};


export default Swipper;
