import { Link } from 'react-router-dom';

const Card = ({ item }) => {
    return (
        <Link to={`/product/${item.name}`} className='flex flex-col border-2 border-black rounded-md pt-2 pb-2 md:pb-6 cursor-pointer'>
            <img src={`${window.location.origin}/assets/${item.image_path}`} className='h-44 md:h-80 object-contain' />
            <div className='flex flex-col px-2 md:items-center text-black text-base md:text-xl  gap-1 '>
                <span className='font-bold uppercase'>{item.name}</span>
                {
                    typeof item.price === 'number' ?
                        <span>From ${item.price.toFixed(2)}</span>
                        : <span >${item.price?.[0].toFixed(2)} - ${item.price?.[1].toFixed(2)}+</span>
                }
            </div>
        </Link>
    )
}

export default Card;